<template>
  <b-card>
    <b-table
      ref="table"
      :no-provider-sorting="true"
      :items="getBookings"
      :fields="tableColumns"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      aria-busy="true"
      class="position-relative"
      responsive
      primary-key="id"
      empty-text="No Requests found"
      show-empty
    >
      <template #cell(id)="{index}">
        {{ index + 1 }}
      </template>

      <template #cell(tickets)="{item}">
        <b-link :to="{name:'tickets-list', params:{ id: item.id } }">
          View Tickets
        </b-link>
      </template>

      <template
        v-if="actionsEnabled"
        #cell(actions)="{item}"
      >
        <b-dropdown
          v-if="item.bookingStatus === 'pending' || item.bookingStatus === 'approved'"
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <div v-if="item.bookingStatus === 'pending'">
            <b-dropdown-item
              v-if="$can('approve','BookingRequestController')"
              @click="approveBooking(item)"
            >
              <feather-icon
                icon="ToggleLeftIcon"
              />
              <span> Approve </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('reject','BookingRequestController')"
              @click="showForm(item,'rejectBooking')"
            >
              <feather-icon
                icon="ToggleRightIcon"
              />
              <span> Reject </span>
            </b-dropdown-item>
          </div>
          <b-dropdown-item
            v-if="item.bookingStatus === 'approved' && $can('cancel','BookingRequestController')"
            @click="showForm(item,'cancelBooking')"
          >
            <feather-icon
              icon="ToggleLeftIcon"
            />
            <span> Cancel </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import bookingStatus from '@/common/compositions/QuickEvents/bookingStatus'

export default {
  name: 'BookingsTable',
  props: {
    getBookings: { type: Function, default: () => null },
    pagination: { type: Object, default: () => {} },
    actionsEnabled: { type: Boolean, default: false },
  },
  data() {
    return {
      tableColumns: [
        { key: 'id', label: '#' },
        { key: 'reserverUser', label: 'Main Attendee' },
        { key: 'ticketsCount' },
        { key: 'paidStatus', formatter: v => (v ? 'paid' : 'Not Paid') },
        { key: 'bookingStatus', label: 'status' },
        { key: 'bookingDate', formatter: v => this.$moment(v).format('YYYY-MM-DD HH:mm A') },
        { key: 'tickets' },
        { key: 'actions' },
      ],
    }
  },
  setup() {
    const { approveBooking } = bookingStatus()
    return { approveBooking }
  },
  methods: {
    showForm(item, actionType) {
      this.$bvModal.show('reason-modal')
      this.setActionData(item, actionType)
    },
    setActionData(item, actionType) {
      this.$emit('update:actionData', { item, actionType })
    },
  },
}
</script>
<style lang="scss">

</style>
