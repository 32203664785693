import axiosInstances from '@/libs/axios-instances'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default function bookingStatus() {
  const { successfulOperationAlert } = handleAlerts()

  const approveBooking = booking => axiosInstances.activities.post(`/internalops/bookings/${booking.id}/approve`).then(() => {
    successfulOperationAlert('Booking is approved successfully')
    booking.bookingStatus = 'approved'
  })

  const rejectBooking = (booking, reason) => axiosInstances.activities.post(`/internalops/bookings/${booking.id}/reject`, {
    reason,
  }).then(() => {
    successfulOperationAlert('Booking is rejected successfully')
    booking.bookingStatus = 'rejected'
  })

  const cancelBooking = (booking, reason) => axiosInstances.activities.post(`/internalops/bookings/${booking.id}/cancel`, {
    reason,
  }).then(() => {
    successfulOperationAlert('Booking is cancelled successfully')
    booking.bookingStatus = 'cancelled'
  })

  return {
    approveBooking,
    rejectBooking,
    cancelBooking,
  }
}
