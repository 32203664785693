<template>
  <div>
    <spinner-loader :loading="loading" />
    <!-- Table -->
    <bookings-table
      ref="parentTable"
      v-bind="{pagination,getBookings,actionsEnabled}"
    />
    <!-- Pagination -->
    <pagination
      :per-page="pagination.perPage"
      :total-rows="pagination.totalRows"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>
<script>
import BookingsTable from '@/common/components/QuickEvents/Bookings/BookingsTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import paginationData from '@/common/compositions/common/paginationData'

export default {
  name: 'BookingsList',
  components: {
    BookingsTable,
    Pagination,
    SpinnerLoader,
  },
  data() {
    return {
      actionData: {},
      actionsEnabled: false,
      loading: true,
    }
  },
  setup() {
    const { pagination } = paginationData()
    return { pagination }
  },
  methods: {
    getBookings() {
      return this.$activities.get(`/internalops/bookings/session/${this.$route.params.id}`).then(res => {
        const { bookings } = res.data.data
        return bookings || []
      }).catch(() => []).finally(() => {
        this.loading = false
      })
    },
  },

}
</script>
<style lang="scss">

</style>
